<template>
  <div class="eden-page--wrapper">
    <div class="card">
      <div class="eden-logo">
        <img :src="getImageUrl('eden.svg')" alt="Eden" />
      </div>
      <div class="card-section">
        <img class="image" :src="getImageUrl('success-state.svg')" alt="Payment Successful" />
      </div>
      <div class="card-section">
        <h4>Payment Successful</h4>
        <p>
          <span v-if="$route.params.service">You have successfully paid for your <strong>{{ formatTitle($route.params.service) }}.</strong></span>
          <span v-else>Your payment has been received.</span>
          <br>Thank you for trusting Eden!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentSuccess',
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped>
h4 {
  margin-bottom: 10px;
}

.image {
  height: 80px;
}
</style>
